import React from 'react'
import Container from '../components/container'

export default function Resume() {
  return (
    <Container>
      Resume Page Coming!
    </Container>
  )
}
